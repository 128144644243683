import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Event/';

const getAllEvents = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetAllEvents`, constants.REQUIRE_INTERCEPTORS);

const getEventById = async (eventId) => axiosInstance.get(`${ENDPOINTORIGIN}GetEventById?eventId=${eventId}`, constants.REQUIRE_INTERCEPTORS);

const createEvent = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}CreateEvent`, model, constants.REQUIRE_INTERCEPTORS);

const updateEvent = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateEvent`, model, constants.REQUIRE_INTERCEPTORS);

const deleteEvent = async (eventId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteEvent?eventId=${eventId}`, constants.REQUIRE_INTERCEPTORS);

const sortEvents = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}SortEvents`, model, constants.REQUIRE_INTERCEPTORS);

export {
  getAllEvents,
  getEventById,
  createEvent,
  updateEvent,
  deleteEvent,
  sortEvents
};