/* eslint-disable react/self-closing-comp */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-one-expression-per-line */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import moment from 'moment';
// COMPONENTS
// ICONS
// import { FaRegCalendarAlt, FaRegClock } from 'react-icons/fa';
// import { MdOutlinePinDrop } from 'react-icons/md';
// import { SiGooglemaps } from 'react-icons/si';
// import { BsFillCalendarWeekFill } from 'react-icons/bs';
// CUSTOM COMPONENTS
import ContentSection from '../../../components/ContentSection';
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
import Banner from '../../../components/Banner';
// ASSETS - EVENT
import BannerImage from '../../../assets/img/banner-image_default.png';
// SERVICES
import * as eventService from '../../../services/management/eventService';

const EventDetailPage = () => {
  const { eventId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [event, setEvent] = useState([]);
  // const iconSize = 40;

  useEffect(() => {
    fetchEvent();
  }, []);

  const fetchEvent = () => {
    setIsLoading(true);

    eventService.getEventById(eventId).then((res) => {
      setEvent(res);
    }).catch(() => {

    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleImageClick = (e) => {
    if (e.target.classList.contains('content-image')) {
      // eslint-disable-next-line max-len
      const backgroundImage = window.getComputedStyle(e.target).getPropertyValue('background-image');
      const url = backgroundImage.slice(4, -1).replace(/"/g, '');
      window.open(url, '_blank');
    }
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isTitleUppercase
        // title="Events"
        isDynamicBanner
        imageSource={BannerImage}
      />

      <CustomBlock className="content-container">
        <CustomBlock className="content-wrapper" onClick={(e) => handleImageClick(e)}>

          {/* EVENT */}
          <ContentSection
            hasRoundCorners
            title={event.title}
            description={(
              <>
                {event.description}<br /><br />
                {event.schedule}
              </>
            )}
            imageSource={`${process.env.REACT_APP_API_URL}Attachments/${event.imageUrl}`}
            primaryActionText={event.primaryButtonLink ? event.primaryButtonText : ''}
            primaryActionOnClick={event.primaryButtonLink ? () => window.open(event.primaryButtonLink, '_blank') : ''}
            secondaryActionText={event.secondaryButtonLink ? event.secondaryButtonText : ''}
            secondaryActionOnClick={event.secondaryButtonLink ? () => window.open(event.secondaryButtonLink, '_blank') : ''}
          />

          <CustomBlock className="event-details-container">
            <>
              <CustomBlock className="detail-items-wrapper">
                {/* DATE */}
                {/* <CustomBlock className="event-detail date">
                  <BsFillCalendarWeekFill size={iconSize} />
                  <p>
                    <span className="bold">{moment(event.start).format('dddd')}</span>
                    <br />
                    <span>{moment(event.start).format('MMMM Do, yyyy')}</span>
                  </p>
                </CustomBlock> */}

                {/* TIME */}
                {/* <CustomBlock className="event-detail time">
                  <BsClockFill size={iconSize} />
                  <p>
                    Starts <span className="bold">{moment(event.start).format('h:mm A')}</span>
                    <br />
                    Ends <span className="bold">{moment(event.end).format('h:mm A')}</span>
                  </p>
                </CustomBlock> */}

                {/* LOCATION */}
                {/* <CustomBlock className="event-detail location">
                  <SiGooglemaps size={iconSize} />

                  <CustomBlock className="location-details-wrapper">
                    <p>
                      <span className="bold">{event.addressTitle}</span>
                    </p>
                    <p>
                      <span>{event.addressLine1}</span>,
                    </p>
                    <p>{event.city}, {event.state}, {event.zipcode}</p>
                  </CustomBlock>

                </CustomBlock> */}
              </CustomBlock>

              {/* <CustomBlock className="map-wrapper">
                <iframe
                  src={event.mapUrl}
                  title="Google Map"
                  width="100%"
                  height="100%"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                >
                </iframe>
              </CustomBlock> */}
            </>
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default EventDetailPage;