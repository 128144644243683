/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
// DEPENDENCIES
import React, { useState } from 'react';
// ICONS
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import Accordion from '../../../components/Accordion';
import Button from '../../../components/Button';
// ASSETS - SMALL BUSINESS RESOURCES PAGE
import BannerImage from '../../../assets/img/banner-image_default.png';
import BackgroundTexture from '../../../assets/img/background-texture_circles.png';
import ResourceImageLED from '../../../assets/img/resource-image_led.png';
import ResourceImageCRPC from '../../../assets/img/resource-image_crpc.png';
import ResourceImageLWC from '../../../assets/img/resource-image_lwc.png';
import ResourceImageBBB from '../../../assets/img/resource-image_bbb.png';

const AddressBlock = ({ physicalAddress = {}, mailingAddress = {} }) => {
  const {
    subheader: physicalSubheader = 'Physical Address',
    line1: physicalLine1,
    line2: physicalLine2,
    phone,
    fax
  } = physicalAddress;
  const {
    line1: mailingLine1,
    line2: mailingLine2,
    subheader: mailingSubheader = 'Mailing Address'
  } = mailingAddress;

  return (
    <>
      {(physicalLine1 || physicalLine2 || phone || fax) && (
        <CustomBlock className="address">
          <p className="subheader fw-600">{physicalSubheader}</p>
          <CustomBlock className="content">
            {physicalLine1 && <p className="line1">{physicalLine1}</p>}
            {physicalLine2 && <p className="line2">{physicalLine2}</p>}
            {phone && <p className="phone">Phone: {phone}</p>}
            {fax && <p className="fax">Fax: {fax}</p>}
          </CustomBlock>
        </CustomBlock>
      )}
      {(mailingLine1 || mailingLine2) && (
        <CustomBlock className="address">
          <p className="subheader fw-600">{mailingSubheader}</p>
          <CustomBlock className="content">
            {mailingLine1 && <p className="line1">{mailingLine1}</p>}
            {mailingLine2 && <p className="line2">{mailingLine2}</p>}
          </CustomBlock>
        </CustomBlock>
      )}
    </>
  );
};

const SmallBusinessResources = () => {
  const [isLoading] = useState(false);

  const openInNewTab = (link) => {
    window.open(link, '_blank');
  };

  const accordionData = [
    {
      id: '0',
      header: 'Register for Sales & Use Tax Collection',
      color: '#013357',
      blend: 'soft-light',
      contentList: (
        <>
          {/* Register for Sales & Use Tax Collection */}
          <CustomBlock className="responsive-content-section has-shadow mb-40">
            <p className="size-mdlg primary-lighter--clr fw-700 pb-10">Register for Sales & Use Tax Collection</p>
            <p className="note-block pb-20">Total Rate 9% (5% local, 4% state)</p>

            <p className="size-mdlg primary-lighter--clr fw-700 pb-10">Local Sales & Use Tax (Parish at Large)</p>
            <p className="note-block pb-10">West Baton Rouge has a unique system of distribution of its general, non-dedicated sales tax revenue. All municipalities and parish government share those revenues on a population ratio, adjusted for census totals every ten years and annual change estimates. All sales tax levies are parish wide, meaning the seller does not have to distinguish where delivery takes place, which eases accounting and reporting responsibilities.</p>
            <p className="note-block">This system also allows all governing authorities to maximize revenues from the parish’s substantial tax base; generated by the petro-chemical industry located here. The current sales tax rate for West Baton Rouge is 5%.</p>

            <p className="size-mdlg primary-lighter--clr fw-700 mt-30 pb-10">State Sales Tax Registration</p>
            <CustomBlock className="address-block">
              <p className="header fw-700 pb-10">Louisiana Department of Revenue and Taxation</p>
              <CustomBlock className="address-listing">
                <AddressBlock
                  physicalAddress={{
                    line1: '617 N 3rd Street',
                    line2: 'Baton Rouge, LA 70801',
                    phone: '225-219-7318'
                  }}
                />
              </CustomBlock>
            </CustomBlock>

            <CustomBlock className="note-block pt-40">
              <p><span className="fw-600">Note: </span>All businesses must have an Occupational License and you must contact each of the offices designated for the specific purposes noted.</p>
            </CustomBlock>
          </CustomBlock>

          {/* West Baton Rouge Parish Revenue Department */}
          <CustomBlock className="responsive-content-section has-shadow mb-40">
            <p className="size-mdlg primary-lighter--clr fw-700 pb-20">West Baton Rouge Parish Revenue Department</p>

            <CustomBlock className="address-block">
              <p className="header fw-700 pb-10">West Baton Rouge Parish Revenue Department</p>
              <CustomBlock className="address-listing">
                <AddressBlock
                  physicalAddress={{
                    line1: '883 7th Street',
                    line2: 'Port Allen, LA 70767',
                    phone: '225-336-2408',
                    fax: ' 225-334-0543'
                  }}
                  mailingAddress={{
                    line1: 'P.O. Box 86',
                    line2: 'Port Allen, LA 70767'
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </CustomBlock>

          {/* Online Resources */}
          <CustomBlock className="responsive-content-section has-shadow mb-40">
            <p className="size-mdlg primary-lighter--clr fw-700 pb-20">Online Resources</p>

            <CustomBlock className="responsive-content-section__listing">
              <ul className="resource-listing">
                <li>
                  <Button
                    type="button"
                    text="Sales & Use Tax Form"
                    className="dark--clr fw-600 size-md"
                    size="md"
                    onClick={() => openInNewTab('https://wbrcouncil.org/DocumentCenter/View/367/West-Baton-Rouge-Sales-Tax-Form-PDF')}
                  />
                </li>
                <li>
                  <Button
                    type="button"
                    text="Louisiana Association of Tax Administrators"
                    className="dark--clr fw-600 size-md"
                    size="md"
                  // onClick={() => openInNewTab('')}
                  />
                </li>
                <li>
                  <Button
                    type="button"
                    text="Sales Tax Online"
                    className="dark--clr fw-600 size-md"
                    size="md"
                  // onClick={() => openInNewTab('')}
                  />
                </li>
                <li>
                  <Button
                    type="button"
                    text="Parish E-File"
                    className="dark--clr fw-600 size-md"
                    size="md"
                  // onClick={() => openInNewTab('')}
                  />
                </li>
              </ul>
            </CustomBlock>

            <CustomBlock className="note-block pt-40">
              <p><span className="fw-600">Note: </span>A refundable security deposit is required on certain types of businesses</p>
            </CustomBlock>
          </CustomBlock>
        </>
      )
    },
    {
      id: '1',
      header: 'Register for An Occupational License',
      color: '#01436d',
      blend: 'soft-light',
      contentList: (
        <>
          {/* Occupational License (Unincorporated Areas of Parish) */}
          <CustomBlock className="responsive-content-section has-shadow mb-40">
            <p className="size-mdlg primary-lighter--clr fw-700 pb-20">Occupational License (Unincorporated Areas of Parish)</p>

            <CustomBlock className="address-block">
              <p className="header fw-700 pb-10">West Baton Rouge Parish Revenue Department</p>
              <CustomBlock className="address-listing">
                <AddressBlock
                  header=""
                  physicalAddress={{
                    line1: '883 7th Street',
                    line2: 'Port Allen, LA 70767',
                    phone: '225-336-2408',
                    fax: '225-334-0543'
                  }}
                  mailingAddress={{
                    line1: 'P.O. Box 86',
                    line2: 'Port Allen, LA 70767'
                  }}
                />
              </CustomBlock>
            </CustomBlock>

            <CustomBlock className="note-block pt-40">
              <p><span className="fw-600">Note: </span>For your own benefit please obtain a zoning designation from the Office of Community Planning and Development. Receiving an OLT is not a clearance for operating a business that is contrary to current zoning ordinances.</p>
            </CustomBlock>
          </CustomBlock>

          {/* Zoning Designation (Unincorporated Areas of Parish) */}
          <CustomBlock className="responsive-content-section has-shadow mb-40">
            <p className="size-mdlg primary-lighter--clr fw-700 pb-20">Zoning Designation (Unincorporated Areas of Parish)</p>
            <CustomBlock className="address-block">
              <p className="header fw-700 pb-10">Office of Community Planning and Development</p>
              <CustomBlock className="address-listing">
                <AddressBlock
                  header="Office of Community Planning and Development"
                  physicalAddress={{
                    line1: '880 N Alexander Avenue',
                    line2: 'Port Allen, LA 70767',
                    phone: '225-336-2434',
                    fax: '225-387-0218'
                  }}
                  mailingAddress={{
                    line1: 'P.O. Box 86',
                    line2: 'Port Allen, LA 70767'
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </CustomBlock>

          {/* Occupational License (Incorporated Areas of Parish) */}
          <CustomBlock className="responsive-content-section has-shadow">
            <p className="size-mdlg primary-lighter--clr fw-700 pb-10">Occupational License (Incorporated Areas of Parish)</p>
            <p className="note-block pb-20">Call each appropriate town/city for requirements and cost of Occupational licenses.</p>

            <CustomBlock className="address-block">
              <CustomBlock className="address-listing">
                <AddressBlock
                  physicalAddress={{
                    subheader: 'Town of Addis',
                    line1: '7818 Highway 1 S',
                    line2: 'Addis, LA 70710',
                    phone: '225-687-4844'
                  }}
                />

                <AddressBlock
                  physicalAddress={{
                    subheader: 'Town of Brusly',
                    line1: '601 S Vaughn Street',
                    line2: 'Brusly, LA 70719',
                    phone: '225-749-2909',
                    fax: '225-749-3874'
                  }}
                />

                <AddressBlock
                  physicalAddress={{
                    subheader: 'City of Port Allen',
                    line1: '375 Court Street',
                    line2: 'Port Allen, LA 70767',
                    phone: '225-346-5670',
                    fax: '225-346-5684'
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </CustomBlock>
        </>
      )
    },
    {
      id: '2',
      header: 'Alcohol Permits',
      color: '#015383',
      blend: 'soft-light',
      contentList: (
        <>
          {/* Local Beer & Liquor Licenses (Unincorporated Areas of Parish) */}
          <CustomBlock className="responsive-content-section has-shadow mb-40">
            <p className="size-mdlg primary-lighter--clr fw-700 pb-10">Local Beer & Liquor Licenses (Unincorporated Areas of Parish)</p>
            <p className="note-block pb-20">Applications and check lists can be obtained from the Parish Council.</p>

            <CustomBlock className="address-block">
              <p className="header fw-700 pb-10">West Baton Rouge Parish Council Clerk</p>
              <CustomBlock className="address-listing">
                <AddressBlock
                  physicalAddress={{
                    line1: '880 N Alexander Avenue',
                    line2: 'Port Allen, LA 70767',
                    phone: '225-214-4242',
                    fax: '225-387-0218'
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </CustomBlock>
        </>
      )
    },
    {
      id: '3',
      header: 'Local Beer & Liquor Licenses (Incorporated Areas of Parish)',
      color: '#016399',
      blend: 'soft-light',
      contentList: (
        <>
          {/* Local Beer & Liquor Licenses (Incorporated Areas of Parish) */}
          <CustomBlock className="responsive-content-section has-shadow mb-40">
            <p className="size-mdlg primary-lighter--clr fw-700 pb-10">Local Beer & Liquor Licenses (Incorporated Areas of Parish)</p>
            <p className="note-block pb-20">Call each appropriate town/city for requirements and cost of licenses.</p>

            <CustomBlock className="address-block">
              <CustomBlock className="address-listing">
                <AddressBlock
                  physicalAddress={{
                    subheader: 'Town of Addis',
                    line1: '7818 Highway 1 S',
                    line2: 'Addis, LA 70710',
                    phone: '225-687-4844'
                  }}
                />

                <AddressBlock
                  physicalAddress={{
                    subheader: 'Town of Brusly',
                    line1: '601 S Vaughn Street',
                    line2: 'Brusly, LA 70719',
                    phone: '225-749-2909',
                    fax: '225-749-3874'
                  }}
                />

                <AddressBlock
                  physicalAddress={{
                    subheader: 'City of Port Allen',
                    line1: '375 Court Street',
                    line2: 'Port Allen, LA 70767',
                    phone: '225-346-5670',
                    fax: '225-346-5684'
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </CustomBlock>

          {/* State Alcohol Beverage Commission */}
          <CustomBlock className="responsive-content-section has-shadow mb-40">
            <p className="size-mdlg primary-lighter--clr fw-700 pb-10">State Alcohol Beverage Commission</p>
            <p className="note-block pb-20"><span className="fw-600">Note: </span>You must contact the following agency only if you are selling alcohol and tobacco.</p>

            <CustomBlock className="address-block">
              <CustomBlock className="address-listing">
                <AddressBlock
                  physicalAddress={{
                    subheader: 'State Alcohol Tobacco Control',
                    line1: '8549 United Plaza Boulevard, 2nd Floor',
                    line2: 'Baton Rouge, LA 70809',
                    phone: '225-925-4041'
                  }}
                />
              </CustomBlock>
            </CustomBlock>

            <CustomBlock className="note-block pt-40">
              <p><span className="fw-600">Note: </span>State and Local Sales Tax Clearance Certificates required for both state and local liquor licenses</p>
            </CustomBlock>
          </CustomBlock>
        </>
      )
    },
    {
      id: '4',
      header: 'Contractor Registration',
      color: '#0173af',
      blend: 'soft-light',
      contentList: (
        <>
          {/* Contractor Registration */}
          <CustomBlock className="responsive-content-section has-shadow mb-40">
            <p className="size-mdlg primary-lighter--clr fw-700 pb-10">Contractor Registration</p>
            <p className="note-block pb-20">You must contact this office for registration to obtain permits and inspections in the unincorporated area of the parish.</p>

            <CustomBlock className="address-block">
              <p className="header fw-700 pb-10">Office of Community Planning and Development (Permit Office)</p>
              <CustomBlock className="address-listing">
                <AddressBlock
                  physicalAddress={{
                    line1: '880 N Alexander Avenue',
                    line2: 'Port Allen LA 70767',
                    phone: '225-336-2434',
                    fax: '225-336-1840'
                  }}
                  mailingAddress={{
                    line1: 'P.O. Box 757',
                    line2: 'Port Allen LA 70767'
                  }}
                />
              </CustomBlock>
            </CustomBlock>

            <CustomBlock className="note-block pt-40">
              <p><span className="fw-600">Note: </span>All Contractors must have a current OLT (from a LA Jurisdiction) and be registered for West Baton Rouge Sales and Use tax.</p>
            </CustomBlock>
          </CustomBlock>

          {/* Health Department Permit */}
          <CustomBlock className="responsive-content-section has-shadow mb-40">
            <p className="size-mdlg primary-lighter--clr fw-700 pb-10">Health Department Permit</p>
            <CustomBlock className="note-block pb-20">
              <p><span className="fw-600">Note: </span>You must contact the Health Department if you are selling food, alcohol, intend to operate a daycare or have a mobile unit.</p>
            </CustomBlock>

            <CustomBlock className="address-block">
              <p className="header fw-700 pb-10">West Baton Rouge Parish Health Department</p>
              <CustomBlock className="address-listing">
                <AddressBlock
                  physicalAddress={{
                    subheader: 'Health Unit Annex',
                    line1: '685 Louisiana Avenue',
                    line2: 'Port Allen, LA 70767',
                    phone: '225-342-2650',
                    fax: '225-214-6548'
                  }}
                  mailingAddress={{
                    line1: 'P.O. Box 227',
                    line2: 'Port Allen, LA 70767'
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </CustomBlock>
        </>
      )
    },
    {
      id: '5',
      header: 'Miscellaneous Agencies',
      color: '#0183bf',
      blend: 'soft-light',
      contentList: (
        <>
          {/* Local Chamber of Commerce */}
          <CustomBlock className="responsive-content-section has-shadow mb-40">
            <p className="size-mdlg primary-lighter--clr fw-700 pb-10">Local Chamber of Commerce</p>

            <CustomBlock className="address-block">
              <p className="header fw-700 pb-10">West Baton Rouge Chamber of Commerce</p>
              <CustomBlock className="address-listing">
                <AddressBlock
                  physicalAddress={{
                    line1: '7520 Highway 1 S',
                    line2: 'Addis, LA 70710',
                    phone: '225-383-3140',
                    fax: '225-685-1044'
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </CustomBlock>

          {/* Filing Corporations, LLC & Trade Name Registration */}
          <CustomBlock className="responsive-content-section has-shadow mb-40">
            <p className="size-mdlg primary-lighter--clr fw-700 pb-10">Filing Corporations, LLC & Trade Name Registration</p>

            <CustomBlock className="address-block">
              <p className="header fw-700 pb-10">Louisiana Secretary of State</p>
              <CustomBlock className="address-listing">
                <AddressBlock
                  physicalAddress={{
                    line1: '8549 United Plaza Boulevard',
                    line2: 'Baton Rouge, LA 70809',
                    phone: '225-925-4704'
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </CustomBlock>

          {/* Obtaining an EIN (Federal ID Number) */}
          <CustomBlock className="responsive-content-section has-shadow mb-40">
            <p className="size-mdlg primary-lighter--clr fw-700 pb-10">Obtaining an EIN (Federal ID Number)</p>

            <CustomBlock className="address-block">
              <p className="header fw-700 pb-10">IRS</p>
              <CustomBlock className="address-listing">
                <AddressBlock
                  physicalAddress={{
                    line1: '2600 Citiplace Center, 3rd Floor',
                    line2: 'Baton Rouge, LA 70808'
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </CustomBlock>
        </>
      )
    },
    {
      id: '6',
      header: 'Important Forms & Resources',
      color: '#0195da',
      blend: 'soft-light',
      contentList: (
        <>
          {/* FORMS */}
          <CustomBlock className="responsive-content-section has-shadow mb-40">
            <p className="size-mdlg primary-lighter--clr fw-700 pb-20">Forms</p>

            <CustomBlock className="responsive-content-section__listing">
              <ul className="resource-listing">
                <li>
                  <Button
                    type="button"
                    text="Instructions for Tax Application"
                    className="dark--clr fw-600 size-md"
                    size="md"
                    onClick={() => openInNewTab('https://www.westbatonrougemuseum.com/DocumentCenter/View/376/Application-Instructions-PDF')}
                  />
                </li>
                <li>
                  <Button
                    type="button"
                    text="Tax Application Form"
                    className="dark--clr fw-600 size-md"
                    size="md"
                    onClick={() => openInNewTab('https://www.westbatonrougemuseum.com/DocumentCenter/View/366/Tax-Application-Form-PDF')}
                  />
                </li>
              </ul>
            </CustomBlock>
          </CustomBlock>

          {/* RESOURCES */}
          <CustomBlock className="responsive-content-section has-shadow mb-40">
            <p className="size-mdlg primary-lighter--clr fw-700 pb-20">Resources</p>

            <CustomBlock className="responsive-content-section__listing">
              <ul className="resource-listing button-images">
                <li>
                  <CustomBlock
                    className="button-image"
                    onClick={() => openInNewTab('https://www.laworks.net/')}
                  >
                    <img
                      src={ResourceImageLWC}
                      title="Louisiana Workforce Commission"
                      alt="Louisiana Workforce Commission"
                    />
                  </CustomBlock>
                </li>
                <li>
                  <CustomBlock
                    className="button-image"
                    onClick={() => openInNewTab('https://crpcla.org/')}
                  >
                    <img
                      src={ResourceImageCRPC}
                      title="Capital Region Planning"
                      alt="Capital Region Planning"
                    />
                  </CustomBlock>
                </li>
                <li>
                  <CustomBlock
                    className="button-image"
                    onClick={() => openInNewTab('https://www.opportunitylouisiana.gov/louisiana-small-business-summit?gclid=Cj0KCQjw06-oBhC6ARIsAGuzdw1Txu0F2acqQyJRS0bAuAJTZYIluTBFUb6p9rIILPsdI4rZeeencFsaArtqEALw_wcB')}
                  >
                    <img
                      src={ResourceImageLED}
                      title="Louisiana Economic Development"
                      alt="Louisiana Economic Development"
                    />
                  </CustomBlock>
                </li>
                <li>
                  <CustomBlock
                    className="button-image"
                    onClick={() => openInNewTab('https://www.bbb.org/')}
                  >
                    <img
                      src={ResourceImageBBB}
                      title="Better Business Bureau"
                      alt="Better Business Bureau"
                    />
                  </CustomBlock>
                </li>
              </ul>
            </CustomBlock>
          </CustomBlock>
        </>
      )
    }
  ];

  return (
    <CustomBlock className="small-business-resources-page">
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Resources"
        imageSource={BannerImage}
      />

      <CustomBlock className="responsive-content-section pt-100 pb-100">
        <p className="responsive-content-section__header">Small Business Resources</p>
        <p className="responsive-content-section__description pb-50">The following is a guide for individuals who are interested in starting a new business in the parish. This list indicates the local and state departments or agencies that issue licenses and permits or have other requirements for opening a new business. All businesses must have an Occupational License and you must contact each of the offices designated for the specific purposes noted.</p>

        <Accordion
          className="accordion-listing"
          data={accordionData}
          backgroundTexture={BackgroundTexture}
        // isInitiallyExpanded
        />
      </CustomBlock>
    </CustomBlock>
  );
};

export default SmallBusinessResources;