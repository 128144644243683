/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
// DEPENDENCIES
import React, { useState } from 'react';
// ICONS
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
// ASSETS - ECONOMIC DEVELOPMENT PAGE
import BannerImage from '../../../assets/img/banner-image_default.png';

const EconomicDevelopmentPage = (props) => {
  const { history } = props;
  const [isLoading] = useState(false);

  return (
    <CustomBlock className="economic-development-page">
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Economic Development"
        imageSource={BannerImage}
      />

      <CustomBlock className="responsive-content-section pt-100">
        <p className="responsive-content-section__header">Unlocking Our Community&rsquo;s Potential</p>
        <p className="responsive-content-section__description mb-30">Economic development describes concerted actions taken for the purpose of economic progress within a specific area. It is the process of generating and sustaining wealth in a community. Every community has unique assets that can be used to attract employers and investment. Building on our assets is one of the best ways our community can stimulate the economy.</p>
        <CustomBlock className="mult-intro pt-60">
          <CustomBlock className="listing-block">
            <CustomBlock className="item" onClick={() => history.push('/education')}>
              <p className="header size-lg fw-500">Education</p>
              <hr />
              <p className="description mid-grey--clr size-sm">Advancing academics and fostering future leaders in West Baton Rouge.</p>
            </CustomBlock>
            <CustomBlock className="item" onClick={() => history.push('/resources')}>
              <p className="header size-lg fw-500">Resources</p>
              <hr />
              <p className="description mid-grey--clr size-sm">Find useful tools and information to help your business succeed.</p>
            </CustomBlock>
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>

    </CustomBlock>
  );
};

export default EconomicDevelopmentPage;