// DEPENDENCIES
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// ICONS
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
// COMPONENTS
import {
  Card,
  Collapse,
  Accordion as BootstrapAccordion
} from 'react-bootstrap';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const Accordion = (props) => {
  const { className, data, backgroundTexture, isInitiallyExpanded } = props;

  const initialOpenItems = data.reduce((acc, item) => {
    acc[item.id] = isInitiallyExpanded;
    return acc;
  }, {});

  const [openItems, setOpenItems] = useState(initialOpenItems);

  const toggleItem = (id) => {
    setOpenItems({ ...openItems, [id]: !openItems[id] });
  };

  const toggleBGStyle = (bgColor, bgBlend) => (
    {
      backgroundImage: `url(${backgroundTexture})`,
      backgroundColor: `${bgColor}`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundBlendMode: `${bgBlend}`,
      borderColor: `${bgColor}`,
    }
  );

  return (
    <BootstrapAccordion className={className}>
      {data.map((item) => (
        <Card key={item.id} className="accordion__card">
          <CustomBlock
            onClick={() => toggleItem(item.id)}
            aria-controls={`body-${item.id}`}
            aria-expanded={openItems[item.id]}
            className={`accordion__card__toggle ${openItems[item.id] ? 'expanded' : ''}`}
            style={
              backgroundTexture && openItems[item.id]
                ? toggleBGStyle(item.color, item.blend)
                : {}
            }
          >
            <h3 className="fw-700">{item.header}</h3>
            <CustomBlock>
              {openItems[item.id] ? (
                <AiOutlineMinus size="30" className="accordion-toggle-icon" />
              ) : (
                <AiOutlinePlus size="30" className="accordion-toggle-icon" />
              )}
            </CustomBlock>
          </CustomBlock>
          <Collapse in={openItems[item.id]}>
            <CustomBlock id={`body-${item.id}`} className="accordion__card__body">
              {item.contentList.length > 0 ? (
                <ul className="accordion__card__body__listing">
                  {item.contentList.map((li) => (
                    <li key={`${item.id}`}>{li}</li>
                  ))}
                </ul>
              ) : (
                item.contentList
              )}
            </CustomBlock>
          </Collapse>
        </Card>
      ))}
    </BootstrapAccordion>
  );
};

// Prop Types
Accordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      header: PropTypes.string.isRequired,
      contentList: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  isInitiallyExpanded: PropTypes.bool.isRequired,
};

export default Accordion;