/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import ContentSection from '../../../components/ContentSection';
import MemberListing from '../../../components/MemberListing';
// ASSETS - TEAM MEMBERS PAGE
import BannerImage from '../../../assets/img/banner-image_default.png';
import DefaultMemberImageDark from '../../../assets/img/board-member_placeholder_dark.png';
// SERVICES AND HELPERS

const TeamMembersPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    getTeamMembers();
  }, []);

  const getTeamMembers = () => {
    setIsLoading(true);
    const members = [
      {
        name: 'Anna Johnson',
        position: 'Executive Director',
        email: 'anna@wbrchamber.org',
        image: '/api/Attachments/TeamMembers/team-member_anna-johnson.png',
        memberType: 'Team Member',
        bioLink: '/bio-anna-johnson',
      },
      {
        name: 'Sharon Oubre-Williams',
        position: 'Membership Director',
        email: 'sharon@wbrchamber.org',
        image: '/api/Attachments/TeamMembers/team-member_sharon-oubre-williams.png',
        memberType: 'Team Member',
        bioLink: '/bio-sharon-oubre-williams',
      },
      {
        name: 'Jennifer Whitehurst',
        position: 'Office Manager',
        email: 'jennifer@wbrchamber.org',
        image: '/api/Attachments/TeamMembers/team-member_jennifer-whitehurst.png',
        memberType: 'Team Member',
        bioLink: '/bio-jennifer-whitehurst',
      },
    ];

    setTeamMembers(members);
    setIsLoading(false);

    // memberService.memberServiceMethod().then((res) => {

    // }).catch(() => {

    // }).finally(() => {
    //   setIsLoading(false);
    // })
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Team Members"
        imageSource={BannerImage}
      />

      <CustomBlock className="content-container--padded">
        <ContentSection
          isTextOnly
          isFullWidthUnpadded
          title="Meet the Team"
          description="Our dedicated team members are the driving force behind West Baton Rouge Chamber of Commerce. With diverse backgrounds and a shared vision, they work tirelessly to support local businesses and foster economic growth. Get to know the professionals who champion our community's prosperity."
        />

        <MemberListing
          members={teamMembers}
          defaultImage={DefaultMemberImageDark}
          containerClassName="team-member-listing"
          className="team-member"
        />
      </CustomBlock>
    </>
  );
};

export default TeamMembersPage;