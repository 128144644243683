/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import moment from 'moment';
// COMPONENTS
// ICONS
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
import TextBlock from '../../../components/TextBlock';
import Banner from '../../../components/Banner';
import ContentSection from '../../../components/ContentSection';
import ContentBlock from '../../../components/ContentBlock';
import SelectBlock from '../../../components/SelectBlock';
import Button from '../../../components/Button';
import TextHeader from '../../../components/TextHeader';
// ASSETS - EVENTS PAGE
import BannerImage from '../../../assets/img/banner-image_default.png';
// SERVICES
import * as eventService from '../../../services/management/eventService';

const EventsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [selectedEventOption, setSelectedEventOption] = useState([]);

  const currentDate = new Date();
  const eventOptions = [
    { label: 'All Events', value: 'all' },
    { label: 'Upcoming Events', value: 'upcoming' },
    { label: 'Past Events', value: 'past' },
    { label: 'Webinars', value: 'webinars' },
    { label: 'Conferences', value: 'conferences' },
    { label: 'Workshops', value: 'workshops' },
  ];

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    switch (selectedEventOption.value) {
      case 'all':
        fetchEvents();
        break;
      case 'past':
        setEvents(pastEvents);
        break;
      case 'upcoming':
        setEvents(upcomingEvents);
        break;
      case 'webinars':
        setEvents(events.filter((event) => event.eventType === 'webinar'));
        break;
      case 'conferences':
        setEvents(events.filter((event) => event.eventType === 'conference'));
        break;
      case 'workshops':
        setEvents(events.filter((event) => event.eventType === 'workshop'));
        break;
      default:
        fetchEvents();
    }
  }, [selectedEventOption]);

  const fetchEvents = () => {
    setIsLoading(true);

    eventService.getAllEvents()
      .then((res) => {
        const activeEvents = res.filter((event) => event.isActive);

        const now = moment();
        const sortedEvents = activeEvents.sort((a, b) => moment(a.start).unix() - moment(b.start).unix());
        const upcoming = sortedEvents.filter((event) => moment(event.start).isAfter(now));
        const past = sortedEvents.filter((event) => moment(event.start).isSameOrBefore(now));

        setEvents(sortedEvents);
        setUpcomingEvents(upcoming);
        setPastEvents(past);
      })
      .catch(() => { })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSelectedEventOption = (selectedOption) => {
    setSelectedEventOption(selectedOption);
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Events"
        imageSource={BannerImage}
      />

      <CustomBlock className="events-page-container content-container--padded">
        <ContentSection
          isTextOnly
          title="Events"
          description="In West Baton Rouge Parish, every season is a reason to celebrate. Our rich history, diverse culture, and community spirit come alive in our array of events, each promising unique experiences and cherished memories. From festive parades and lively galas to educational workshops and community outreaches, West Baton Rouge has something for everyone. Dive into our vibrant local scene, reconnect with neighbors, and make new friends along the way. Explore our event listings below and mark your calendars – we can't wait to celebrate with you!"
        // isFullWidth
        // imageSource={EventsBannerImage}
        // hasRoundCorners
        />

        <CustomBlock className="content-section pt-0 mb-80">
          <CustomBlock className="select-block-container">
            <ContentBlock cols={4}>
              <SelectBlock
                label="Events Filter"
                labelColor="primary--clr"
                options={eventOptions}
                placeholder="Select how events appear…"
                value={selectedEventOption}
                onChange={(selectedOption) => handleSelectedEventOption(selectedOption)}
                isSearchable={false}
              />
            </ContentBlock>
          </CustomBlock>
        </CustomBlock>

        <CustomBlock className="content-section event-listing-container pt-0 mb-120">
          {events.map((event) => (
            <CustomBlock className={`event-item ${new Date(event.start) < currentDate ? 'event-type__past' : 'event-type__upcoming'}`}>
              <CustomBlock
                className="event-item__image mb-20"
                style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}Attachments/${event.imageUrl})` }}
              />
              <TextHeader
                title={event.title}
                subtitle={event.addressTitle}
                size="md"
                className="event-item__header pb-0 mb-5"
                subtitleSize="md"
                subtitleClassName="pb-0 dark--clr fw-500 mb-10"
              />
              <TextBlock
                text={moment(event.start).format('MMMM Do')}
                type="description"
                className="event-item__date fw-500 mb-0"
              />

              <CustomBlock className="event-item__actions content-container--actions flex-start pl-0 mt-30">
                <Button
                  text="Event Details"
                  size="xs"
                  type="button"
                  className="primary--bg ml-0"
                  to={`/events/${event.id}`}
                />
                {event.primaryButtonLink && (
                  <Button
                    text={event.primaryButtonText}
                    size="xs"
                    type="button"
                    className="primary-lighter--bg"
                    onClick={() => {
                      window.open(event.primaryButtonLink);
                    }}
                  />
                )}
              </CustomBlock>
            </CustomBlock>
          ))}
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default EventsPage;