/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-array-index-key */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
import {
  DragDropContext,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';
// ICONS
import { MdOutlineEvent } from 'react-icons/md';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import OverLay from '../../../../components/Overlay';
import ModalBlock from '../../../../components/ModalBlock';
// HELPERS AND SERVICES
import * as eventService from '../../../../services/management/eventService';
import * as helper from '../../../../helpers/helper';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as authRedux from '../../../../redux/authRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const EventListingPage = (props) => {
  const {
    history,
    showAlert,
    auth,
    showConfirmModal,
    hideConfirmModal
  } = props;
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [sortList, setSortList] = useState([]);
  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    getAllEvents();
  }, []);

  useEffect(() => {
    setSortList(events.sort((a, b) => a - b));
  }, [events]);

  const getAllEvents = () => {
    setIsLoading(true);
    eventService.getAllEvents().then((res) => {
      setEvents(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const tempArr = helper.reOrderDnd(
      sortList,
      result.source.index,
      result.destination.index
    );

    setSortList(tempArr);
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="View"
              className="primary--bg ml-0"
              size="xxs"
              onClick={() => {
                history.push(`/cms/management/event/${events[dataIndex].id}`);
              }}
            />

            {
              auth.isAdmin
              && (
                <Button
                  text="Delete"
                  className="danger--bg ml-5"
                  size="xxs"
                  onClick={() => {
                    showConfirmModal({
                      title: 'Remove Event',
                      text: 'Are you sure you want to remove this event?',
                      rightBtnText: 'Confirm',
                      btnAction: () => {
                        deleteEvent(events[dataIndex].id);
                      }
                    });
                  }}
                />
              )
            }
          </CustomBlock>
        )
      }
    },
    {
      name: 'title',
      label: 'Event',
      options: columnOptions,
    },
    {
      name: 'start',
      label: 'Date',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const { start } = events[dataIndex];

          return (
            <p>{moment(start).format('MMMM Do, yyyy')}</p>
          );
        }
      },
    },
    {
      name: 'end',
      label: 'Expiration',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const { end } = events[dataIndex];
          const daysLeft = moment.duration(moment(end).diff(moment())).asDays();

          return daysLeft > 0 ? <p style={{ color: 'green' }}>Upcoming</p> : <p style={{ color: 'red' }}>Past</p>;
        }
      },
    },
    {
      name: 'isActive',
      label: 'Is Active',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const { isActive } = events[dataIndex];

          return (
            <p>{isActive ? 'Yes' : 'No'}</p>
          );
        }
      },
    }
  ];

  const deleteEvent = (eventId) => {
    setIsLoading(true);

    eventService.deleteEvent(eventId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      getAllEvents();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  const sortEvents = () => {
    setIsLoading(true);

    eventService.sortEvents(sortList).then((res) => {
      getAllEvents();
      showAlert({ text: res.message, state: 'success' });
      setIsSortModalOpen(false);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Events"
                headerSize="lg"
                primaryButtonText={auth.isAdmin ? 'Add Event' : ''}
                primaryButtonIconLeft={<MdOutlineEvent className={iconColor} size={iconSize} />}
                primaryButtonOnClick={() => {
                  history.push('/cms/management/create-event');
                }}
                secondaryButtonText="Sort Sections"
                secondaryButtonOnClick={() => {
                  setIsSortModalOpen(true);
                }}
              />

              <CustomBlock>
                <MUIDataTable
                  data={events}
                  columns={columns}
                  options={{
                    selectableRows: 'none',
                    download: true,
                    print: false,
                    jumpToPage: true,
                    textLabels: { pagination: { jumpToPage: 'Page No:' } }
                  }}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* SORT MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={isSortModalOpen}
        size="md"
        contentHeader="Sort Sections"
        primaryModalActionText="Sort"
        primaryModalActionOnClick={sortEvents}
        onHide={() => {
          setIsSortModalOpen(false);
        }}
      >
        <Section hasNoContainer>
          {
            sortList.length > 0
              ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(droppableProvided) => (
                      <div
                        ref={droppableProvided.innerRef}
                      >
                        {sortList.map((item, index) => (
                          <Draggable
                            key={index} draggableId={`${index}`}
                            index={index}
                          >
                            {(draggableProvided) => (
                              <div
                                key={index}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                className="content-container--card-style light-grey--sbg pt-15 pb-15 pl-20 pr-20 mb-15"
                              >
                                <p className="main-font fw-500">{item.title}</p>
                              </div>

                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <CustomBlock className="content-container--card-style light-grey--sbg pt-15 pb-15 pl-20 pr-20 mb-15">
                  <p className="main-font fw-500">No Items Available</p>
                </CustomBlock>
              )
          }
        </Section>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...authRedux.actions,
    ...alert.actions,
    ...confirmModal.actions
  }
)(EventListingPage);