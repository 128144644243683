/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
// DEPENDENCIES
import React, { useState } from 'react';
// ICONS
import { BiSolidQuoteLeft } from 'react-icons/bi';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import Button from '../../../components/Button';
// ASSETS - ECONOMIC DEVELOPMENT PAGE
import BannerImage from '../../../assets/img/banner-image_default.png';

const EducationPage = () => {
  const [isLoading] = useState(false);

  const openInNewTab = (link) => {
    window.open(link, '_blank');
  };

  return (
    <CustomBlock className="education-page">
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Education"
        imageSource={BannerImage}
      />

      <CustomBlock className="responsive-content-section pt-100 pb-100">
        <p className="responsive-content-section__header">Education</p>
        <p className="responsive-content-section__description mb-30">West Baton Rouge Parish Schools continue to rise, with academic gains and upgraded school facilities across the parish. Indeed, many new residents are moving to West Baton Rouge to enroll their kids into the WBR School System because of our focus on academics, supporting students, security, and community engagement.</p>
        <p className="responsive-content-section__description mb-40">Superintendent Chandler Smith, the School Board and the school system staff are committed to a primary goal of making West Baton Rouge Parish an “A” rated school system in Louisiana within five years.</p>

        <CustomBlock className="responsive-content-section__listing mb-40">
          <h3 className="responsive-content-section__subheader mb-15">The School System has developed five goals to reach that mark. They include:</h3>
          <ul className="numbered">
            <li>Providing optimal learning environments at every school.</li>
            <li>Improving security and support for student emotional learning.</li>
            <li>Building highly effective instructional teams.</li>
            <li>Aligning school system finances with these goals.</li>
            <li>Enhancing communication with parents and the community.</li>
          </ul>
        </CustomBlock>

        <CustomBlock className="responsive-content-section__quote mt-80 mb-80">
          <BiSolidQuoteLeft className="quote-icon" />
          <p className="responsive-content-section__quote__text">
            There is one common theme among the strategies of these goals. They all require a culture of collaboration. And that spirit of working together, helping each other, and looking out for each student, is the foundation of our school system. This will foster great things as we continue to move our school system forward. We invite you to come visit our schools and see the West Baton Rouge difference! It’s one reason the West Side is the Best Side.
          </p>
          <hr />
          <p className="responsive-content-section__quote__text fw-500">Superintendent Smith</p>
        </CustomBlock>

        <CustomBlock className="responsive-content-section__listing mb-80">
          <h2 className="responsive-content-section__subheader mb-20">Schools in the Parish</h2>
          <ul className="resource-listing">
            <li>
              <Button
                type="button"
                text="WBR Schools"
                className="dark--clr fw-600 size-md"
                size="md"
                onClick={() => openInNewTab('https://www.wbrschools.net/')}
              />
            </li>
            <li>
              <Button
                type="button"
                text="Holy Family"
                className="dark--clr fw-600 size-md"
                size="md"
                onClick={() => openInNewTab('https://holyfamilyschool.com/')}
              />
            </li>
            <li>
              <Button
                type="button"
                text="St. John"
                className="dark--clr fw-600 size-md"
                size="md"
                onClick={() => openInNewTab('https://www.stjohnschool.org/')}
              />
            </li>
            <li>
              <Button
                type="button"
                text="Christian Academy of Louisiana"
                className="dark--clr fw-600 size-md"
                size="md"
                onClick={() => openInNewTab('https://caofla.com/')}
              />
            </li>
          </ul>
        </CustomBlock>

        <CustomBlock className="responsive-content-section__listing mb-40">
          <h2 className="responsive-content-section__subheader mb-20">Higher Education</h2>
          <ul className="resource-listing">
            <li>
              <Button
                type="button"
                text="Louisiana State University"
                className="dark--clr fw-600 size-md"
                size="md"
                onClick={() => openInNewTab('https://www.lsu.edu/')}
              />
            </li>
            <li>
              <Button
                type="button"
                text="Southern University"
                className="dark--clr fw-600 size-md"
                size="md"
                onClick={() => openInNewTab('http://www.subr.edu/')}
              />
            </li>
            <li>
              <Button
                type="button"
                text="Baton Rouge Community College"
                className="dark--clr fw-600 size-md"
                size="md"
                onClick={() => openInNewTab('https://www.mybrcc.edu/')}
              />
            </li>
            <li>
              <Button
                type="button"
                text="River Parishes Community College"
                className="dark--clr fw-600 size-md"
                size="md"
                onClick={() => openInNewTab('https://www.rpcc.edu/')}
              />
            </li>
          </ul>
        </CustomBlock>
      </CustomBlock>
    </CustomBlock>
  );
};

export default EducationPage;