/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import ContactForm from '../../../components/ContactForm';
// import ContentSection from '../../../components/ContentSection';
// ASSETS - CONTACT PAGE
import BannerImage from '../../../assets/img/banner-image_default.png';
// import IntroSectionImage from '../../../assets/img/content-image_contact-page-intro.png';
// SERVICES AND HELPERS
import * as auth from '../../../redux/authRedux';
import * as alertToast from '../../../redux/alertToastRedux';
import * as emailService from '../../../services/management/emailService';

const ContactPage = (props) => {
  const { showAlert, history, cmsInfo } = props;
  const [isLoading, setIsLoading] = useState(false);
  // const getDirectionsLink = 'https://www.google.com/maps/place/142+Del+Norte+Ave,+Denham+Springs,+LA+70726/@30.4706975,-90.9551617,17z/data=!3m1!4b1!4m6!3m5!1s0x8626bef0ef5cd50d:0x915fc5c888dc7ba6!8m2!3d30.4706975!4d-90.9551617!16s%2Fg%2F11c28v5b_f?entry=ttu';
  const formConfig = [
    { name: 'firstName', label: 'First Name', required: true, placeholder: 'e.g. John', max: 100 },
    { name: 'lastName', label: 'Last Name', required: true, placeholder: 'e.g. Smith', max: 100 },
    { name: 'email', label: 'Email Address', required: true, placeholder: 'e.g. johnsmith@example.com', max: 100, email: true },
    { name: 'phoneNumber', label: 'Phone Number', placeholder: 'e.g. (555) 555-5555', max: 50 },
    { name: 'message', label: 'Message', required: true, placeholder: 'Provide your message here...', textarea: true, cols: 12 },
  ];

  const submitForm = async (values) => {
    setIsLoading(true);
    await emailService.sendEmail(values).then(() => {
      showAlert({ text: 'Success! Your message has been received.', state: 'success' });
      history.push('/contact');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Contact"
        imageSource={BannerImage}
      />

      <CustomBlock className="content-container--padded">
        {/* <ContentSection
          hasRoundCorners
          isFullWidth
          title="Connect With Us"
          description="Your dream property is just a message away. Contact us to make it a reality."
          imageSource={IntroSectionImage}
          primaryActionText="Get Directions"
          primaryActionOnClick={() => window.open(getDirectionsLink, '_blank')}
          primaryActionColor="primary--sbg"
        /> */}

        <CustomBlock className="custom-content-section" style={{ position: 'relative', zIndex: '2' }}>
          <h1><span className="custom-header--primary-light">WBR</span> Chamber of Commerce</h1>
          <br />

          <h2>Location</h2>
          <h3 className="primary-lighter--clr mt-10">Physical Address</h3>
          <p>{cmsInfo.streetAddress1}, {cmsInfo.streetAddress2}</p>
          <p>{cmsInfo.city}, {cmsInfo.state}, {cmsInfo.zipCode}</p>
          <h3 className="primary-lighter--clr mt-10">Mailing Address</h3>
          <p>P.O. Box 448</p>
          <br />

          <h2>Contact</h2>
          <p>Email: {cmsInfo.emailAddress}</p>
          <p>Phone: {cmsInfo.phoneNumber}</p>
          <p>Fax: (225) 685-1044</p>
          <br />
          {(cmsInfo.primaryWeekDayHours || cmsInfo.secondaryWeekDayHours || cmsInfo.primaryWeekEndHours || cmsInfo.secondaryWeekEndHours) && (
            <>
              <h2>Hours of Operation</h2>
              {(cmsInfo.primaryWeekDayHours || cmsInfo.secondaryWeekDayHours) && (
                <CustomBlock className="mt-5 mb-5">
                  <h3 className="primary-lighter--clr">Weekdays</h3>

                  {cmsInfo.primaryWeekDayHours && (
                    <p>{cmsInfo.primaryWeekDayHours}</p>
                  )}
                  {cmsInfo.secondaryWeekDayHours && (
                    <p>{cmsInfo.secondaryWeekDayHours}</p>
                  )}
                </CustomBlock>
              )}
              {(cmsInfo.primaryWeekEndHours || cmsInfo.secondaryWeekEndHours) && (
                <CustomBlock>
                  <h3 className="primary-lighter--clr">Weekends</h3>
                  {cmsInfo.primaryWeekEndHours && (
                    <p>{cmsInfo.primaryWeekEndHours}</p>
                  )}
                  {cmsInfo.secondaryWeekEndHours && (
                    <p>{cmsInfo.secondaryWeekEndHours}</p>
                  )}
                </CustomBlock>
              )}
            </>
          )}
        </CustomBlock>

        <CustomBlock className="responsive-content-section">
          <svg
            width="100%"
            height="600"
            // viewBox="0 0 1901 602"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            className="background-decoration--dark-light-waves"
            style={{ transform: 'scaleX(-1)', top: '-200px' }}
          >
            <path
              d="M0.0918495 73.5986L0.0918495 397.88C107.439 321.334 469.533 120.143 987.763 199.274C1483.25 274.932 1645.64 264.478 1900.09 205.518L1900.09 172.9C1530.82 216.344 1327.56 180.658 922.596 73.5986C582.799 -25.707 342.303 -22.6017 0.0918495 73.5986Z"
              fill="#94DDFF"
              fillOpacity="0.835294"
            />
            <path
              d="M0.0918495 397.88L0.0918495 601.845C116.748 476.447 478.554 214.386 987.763 252.028C1489.86 289.144 1583.53 303.825 1900.09 221.196L1900.09 205.518C1645.64 264.478 1483.25 274.932 987.763 199.274C469.533 120.143 107.439 321.334 0.0918495 397.88Z"
              fill="#C3E9FB"
              fillOpacity="0.835294"
            />
          </svg>
        </CustomBlock>

        <CustomBlock className="content-section" style={{ position: 'relative', zIndex: '2' }}>
          <CustomBlock className="content-container--card-style--with-shadow">
            <ContactForm
              formConfig={formConfig}
              onSubmit={(values) => submitForm(values)}
              title={<p><span className="custom-header--primary-light">Questions?</span> We Have Answers</p>}
              className="mb-20"
            />
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alertToast.actions
})(ContactPage);