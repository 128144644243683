/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import ContentSection from '../../../components/ContentSection';
import MemberListing from '../../../components/MemberListing';
import TextHeader from '../../../components/TextHeader';
// ASSETS - BOARD OF DIRECTORS PAGE
import BannerImage from '../../../assets/img/banner-image_default.png';
import DefaultMemberImageDark from '../../../assets/img/board-member_placeholder_dark.png';
// SERVICES AND HELPERS

const BoardOfDirectorsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [executiveCommitteeMembers, setExecutiveCommitteeMembers] = useState([]);
  const [boardMembers, setBoardMembers] = useState([]);

  useEffect(() => {
    getMembers();
  }, []);

  const getMembers = () => {
    setIsLoading(true);
    // const members = [
    //   {
    //     name: 'LeAnn Stelly',
    //     // position: 'Treasurer',
    //     company: 'Cox Communications',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_leann-stelly.png',
    //     memberType: 'Executive Committee'
    //   },
    //   {
    //     name: 'Bryan Casebonne',
    //     // position: '',
    //     company: 'Shintech',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_bryan-casebonne.png',
    //     memberType: 'Executive Committee'
    //   },
    //   {
    //     name: 'Ashley Coco',
    //     // position: '',
    //     company: 'Stewart Title Company',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_ashley-coco.png',
    //     memberType: 'Executive Committee'
    //   },
    //   {
    //     name: 'Cheri Ausberry',
    //     // position: '',
    //     company: 'Entergy',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_cheri-ausberry.png',
    //     memberType: 'Executive Committee'
    //   },
    //   {
    //     name: 'Arron Pierre',
    //     // position: 'Chair Elect',
    //     company: 'A. Wilbert Sons',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_arron-pierre.png',
    //     memberType: 'Executive Committee'
    //   },
    //   {
    //     name: 'Glynna Mayers',
    //     // position: 'Chair',
    //     company: 'Dow Plaquemine',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_glynna-mayers.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Rick Ward',
    //     // position: 'Chair',
    //     company: 'Clayton, Fruge’ & Ward Law',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_rick-ward.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Chris Reeves',
    //     // position: 'Chair',
    //     company: 'Edward Jones',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_chris-reeves.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Jason Hammack',
    //     // position: 'Chair',
    //     company: 'Court Street Café',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_jason-hammack.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Scott Roe',
    //     // position: 'Chair',
    //     company: 'Spillway Sportsman',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_scott-roe.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Glen Daigle',
    //     // position: '',
    //     company: 'B1 Bank',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_glen-daigle.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Jennifer Strong',
    //     // position: '',
    //     company: 'Olin',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_jennifer-strong.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Lindsey Fourroux',
    //     // position: '',
    //     company: 'Brians Furniture',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_lindsey-fourroux.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Toby Willis',
    //     // position: '',
    //     company: 'Edward Jones',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_tony-willis.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Annie Claire Bass',
    //     // position: '',
    //     company: 'SoSis',
    //     companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_annie-claire-bass.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Bridget Easley',
    //     // position: '',
    //     company: 'Easley Builders',
    //     companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_bridget-easley.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Blaine Rabalais',
    //     // position: '',
    //     company: 'Town of Brusly',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_blaine-rabalais.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Ben Graham',
    //     // position: '',
    //     company: 'Stirling Properties',
    //     companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_ben-graham.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Rawlston Phillips',
    //     // position: '',
    //     company: 'Sun Plus Inc',
    //     companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_rawlston-phillips.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Julie Mayeux',
    //     // position: '',
    //     company: 'WBR Schools',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_julie-mayeux.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Sandra Hughes',
    //     // position: 'Chair Ex-Officio',
    //     company: 'Lofton Staffing & Security Services',
    //     companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_sandra-hughes.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Roland Gaudet',
    //     // position: '',
    //     company: 'Citizens Bank',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_roland-gaudet.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Phillip Bourgoyne',
    //     // position: '',
    //     company: 'Parish Government Representative',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_phillip-bourgoyne.png',
    //     memberType: 'Board Member'
    //   },
    //   {
    //     name: 'Tyler Gray',
    //     // position: '',
    //     company: 'Placid Refining',
    //     // companyLink: '',
    //     image: '/api/Attachments/BoardMembers/board-member_tyler-gray.png',
    //     memberType: 'Board Member'
    //   },
    // ];

    const members = [
      {
        name: 'LeAnn Stelly',
        company: 'Rev',
        image: '/api/Attachments/BoardMembers/board-member_leann-stelly.png',
        memberType: 'Executive Committee'
      },
      {
        name: 'Bryan Casebonne',
        company: 'Shintech',
        image: '/api/Attachments/BoardMembers/board-member_bryan-casebonne.png',
        memberType: 'Executive Committee'
      },
      {
        name: 'Ashley Coco',
        company: 'Stewart Title Company',
        image: '/api/Attachments/BoardMembers/board-member_ashley-coco.png',
        memberType: 'Executive Committee'
      },
      {
        name: 'Cheri Ausberry',
        company: 'Entergy',
        image: '/api/Attachments/BoardMembers/board-member_cheri-ausberry.png',
        memberType: 'Executive Committee'
      },
      {
        name: 'Desiree Lemoine',
        company: 'TJC Group',
        image: '/api/Attachments/BoardMembers/board-member_desiree-lemoine.png',
        memberType: 'Executive Committee'
      },
      {
        name: 'Jennifer Strong',
        company: 'Olin',
        image: '/api/Attachments/BoardMembers/board-member_jennifer-strong.png',
        memberType: 'Board Member'
      },
      {
        name: 'Toby Willis',
        company: 'Edward Jones',
        image: '/api/Attachments/BoardMembers/board-member_tony-willis.png',
        memberType: 'Board Member'
      },
      {
        name: 'Tamie Martin',
        company: 'WBR Parish Library',
        image: '/api/Attachments/BoardMembers/board-member_tammie-martin.png',
        memberType: 'Board Member'
      },
      {
        name: 'Brandy Tyson',
        company: 'Baton Rouge Community College',
        image: '/api/Attachments/BoardMembers/board-member_brandy-tyson.png',
        memberType: 'Board Member'
      },
      {
        name: 'Michael Huye',
        company: 'Wilson Warehouse',
        image: '/api/Attachments/BoardMembers/board-member_michael-huye.png',
        memberType: 'Board Member'
      },
      {
        name: 'Clay Elliott',
        company: 'Oak Capital Financial Advisors',
        image: '/api/Attachments/BoardMembers/board-member_clay-elliott.png',
        memberType: 'Board Member'
      },
      {
        name: 'Ashley Bergeron',
        company: 'Studio C323',
        image: '/api/Attachments/BoardMembers/board-member_ashley-bergeron.png',
        memberType: 'Board Member'
      },
      {
        name: 'Rory Denicola',
        company: 'ExxonMobil',
        image: '/api/Attachments/BoardMembers/board-member_rory-denicola.png',
        memberType: 'Board Member'
      },
      {
        name: 'Trenton Gordon',
        company: 'Trenton Gordon Photography',
        image: '/api/Attachments/BoardMembers/board-member_trenton-gordon.png',
        memberType: 'Board Member'
      },
      {
        name: 'Mary Meyer',
        company: 'Dow',
        image: '/api/Attachments/BoardMembers/board-member_mary-meyer.png',
        memberType: 'Board Member'
      },
      {
        name: 'Jason Manola',
        company: 'WBR Parish Government Representative',
        image: '/api/Attachments/BoardMembers/board-member_jason-manola.png',
        memberType: 'Board Member'
      },
      {
        name: 'Sandra Hughes',
        company: 'Lofton Staffing & Security Services',
        image: '/api/Attachments/BoardMembers/board-member_sandra-hughes.png',
        memberType: 'Board Member'
      },
      {
        name: 'Roland Gaudet',
        company: 'Citizens Bank',
        image: '/api/Attachments/BoardMembers/board-member_roland-gaudet.png',
        memberType: 'Board Member'
      },
      {
        name: 'Blaine Rabalais',
        company: 'Town of Brusly',
        image: '/api/Attachments/BoardMembers/board-member_blaine-rabalais.png',
        memberType: 'Board Member'
      },
      {
        name: 'Ben Graham',
        company: 'Stirling Properties',
        image: '/api/Attachments/BoardMembers/board-member_ben-graham.png',
        memberType: 'Board Member'
      },
      {
        name: 'Rawlston Phillips',
        company: 'Sun Plus Inc',
        image: '/api/Attachments/BoardMembers/board-member_rawlston-phillips.png',
        memberType: 'Board Member'
      },
      {
        name: 'Julie Mayeux',
        company: 'WBR Schools',
        image: '/api/Attachments/BoardMembers/board-member_julie-mayeux.png',
        memberType: 'Board Member'
      }
    ];

    const executiveMembers = members.filter((member) => member.memberType === 'Executive Committee');
    const boardMembersData = members.filter((member) => member.memberType === 'Board Member');

    setExecutiveCommitteeMembers(executiveMembers);
    setBoardMembers(boardMembersData);

    setIsLoading(false);

    // memberService.memberServiceMethod().then((res) => {

    // }).catch(() => {

    // }).finally(() => {
    //   setIsLoading(false);
    // })
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Board of Directors"
        imageSource={BannerImage}
      />

      <CustomBlock className="content-container--padded">
        {/* <CustomBlock className="custom-content-section">
          <Image
            hasRoundCorners
            alt="Board of Directors Event Image"
            source={BoardOfDirectorsEventImage}
            className="custom-image"
          />
        </CustomBlock> */}

        <ContentSection
          isTextOnly
          isFullWidthUnpadded
          title="Meet Our Board"
          description="Our Board guides the West Baton Rouge Chamber of Commerce with expertise and vision, serving both our members and the wider community towards a brighter, more prosperous future."
        />

        {/* EXECUTIVE COMMITTEE MEMBER LISTING */}
        <CustomBlock className="content-container--padded">
          <CustomBlock className="content-section pb-0 pt-0">
            <TextHeader
              title="Executive Committee"
              size="lg"
            />
          </CustomBlock>

          <MemberListing
            members={executiveCommitteeMembers}
            defaultImage={DefaultMemberImageDark}
          />
        </CustomBlock>

        {/* BOARD MEMBER LISTING */}
        <CustomBlock className="content-container--padded">
          <CustomBlock className="content-section pb-0 pt-0">
            <TextHeader
              title="Board Members"
              size="lg"
            />
          </CustomBlock>

          <MemberListing
            members={boardMembers}
            defaultImage={DefaultMemberImageDark}
          />
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default BoardOfDirectorsPage;