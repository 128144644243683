// DEPENDENCIES
import React, { useState } from 'react';
// ICONS
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import Button from '../../../components/Button';
// ASSETS - LIFESTYLE AND COMMUNITY PAGE
import BannerImage from '../../../assets/img/banner-image_default.png';

const LifestyleAndCommunityPage = () => {
  const [isLoading] = useState(false);

  const openInNewTab = (link) => {
    window.open(link, '_blank');
  };

  return (
    <CustomBlock className="education-page">
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Lifestyle & Community"
        imageSource={BannerImage}
      />

      <CustomBlock className="responsive-content-section pt-100 pb-100">
        <CustomBlock className="responsive-content-section__listing mb-100">
          <h2 className="responsive-content-section__header mb-20">Healthcare</h2>
          <ul className="resource-listing">
            <li>
              <Button
                type="button"
                text="Ochsner"
                className="dark--clr fw-600 size-md"
                size="md"
                onClick={() => openInNewTab('https://www.ochsner.org/?home=no-thanks')}
              />
            </li>
            <li>
              <Button
                type="button"
                text="Baton Rouge General"
                className="dark--clr fw-600 size-md"
                size="md"
                onClick={() => openInNewTab('https://www.brgeneral.org/')}
              />
            </li>
            <li>
              <Button
                type="button"
                text="Our Lady of the Lake"
                className="dark--clr fw-600 size-md"
                size="md"
                onClick={() => openInNewTab('https://www.fmolhs.org/')}
              />
            </li>
            <li>
              <Button
                type="button"
                text="RKM Primary Care"
                className="dark--clr fw-600 size-md"
                size="md"
                onClick={() => openInNewTab('https://www.rkmcare.org/')}
              />
            </li>
            <li>
              <Button
                type="button"
                text="Patient Plus Urgent Care Clinic"
                className="dark--clr fw-600 size-md"
                size="md"
                onClick={() => openInNewTab('https://patientplusuc.com/')}
              />
            </li>
          </ul>
        </CustomBlock>

        <CustomBlock className="responsive-content-section__listing mb-100">
          <h2 className="responsive-content-section__header mb-20">Community Services</h2>
          <ul className="resource-listing">
            <li>
              <Button
                type="button"
                text="Council On Aging"
                className="dark--clr fw-600 size-md"
                size="md"
                onClick={() => openInNewTab('https://www.wbrcoa.org/')}
              />
            </li>
            <li>
              <Button
                type="button"
                text="Port Allen Rotary Club"
                className="dark--clr fw-600 size-md"
                size="md"
                onClick={() => openInNewTab('https://www.facebook.com/PortAllenRotaryClub/')}
              />
            </li>
          </ul>
        </CustomBlock>

        <CustomBlock className="responsive-content-section__listing mb-100">
          <h2 className="responsive-content-section__header mb-20">Parks & Recreation</h2>
          <ul className="resource-listing">
            <li>
              <Button
                type="button"
                text="WBR Parks and Recreation"
                className="dark--clr fw-600 size-md"
                size="md"
                onClick={() => openInNewTab('https://westbatonrougemuseum.org/156/Parks-Recreation')}
              />
            </li>
            <li>
              <Button
                type="button"
                text="Tourism Center"
                className="dark--clr fw-600 size-md"
                size="md"
                onClick={() => openInNewTab('https://www.westbatonrouge.net/')}
              />
            </li>
          </ul>
        </CustomBlock>
      </CustomBlock>
    </CustomBlock>
  );
};

export default LifestyleAndCommunityPage;