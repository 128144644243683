/* eslint-disable max-len */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// COMPONENTS
import Slider from 'react-slick';
// ICONS
import DevelopmentIcon from '../../../assets/img/icon_economic-workforce-dev.svg';
import LeadershipIcon from '../../../assets/img/icon_leadership.svg';
import MembershipIcon from '../../../assets/img/icon_membership.svg';
import RelationsIcon from '../../../assets/img/icon_relations.svg';
// CUSTOM COMPONENTS
// import Section from '../../../components/Section';
// import ContentBlock from '../../../components/ContentBlock';
import ContentSection from '../../../components/ContentSection';
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
import TextBlock from '../../../components/TextBlock';
import Image from '../../../components/Image';
import Banner from '../../../components/Banner';
import Button from '../../../components/Button';
// import ContentStrip from '../../../components/ContentStrip';
// ASSETS - APP LINKS
// import AppStoreBadge from '../../../assets/img/app-store-badge.svg';
// import GooglePlayBadge from '../../../assets/img/google-play-badge.svg';
// ASSETS - CONTENT IMAGES
// import BackgroundPatternCircles from '../../../assets/img/background-image_circles.png';
// import BackgroundPatternCircles from '../../../assets/img/background-texture_circles.png';
import BackgroundPatternCircles from '../../../assets/img/banner-image_homepage-default.png';
import BoardOfDirectorsImage from '../../../assets/img/content-image_board-of-directors.png';
import BannerImage from '../../../assets/img/banner-image_default.png';
import BackgroundDecorationDots from '../../../assets/img/background-decoration_dots.svg';
import WelcomeToWBRCC from '../../../assets/img/content-image_welcome-to-wbrcc.svg';
// HELPERS
import constants from '../../../constants/constants';
// SERVICES

const HomePage = (props) => {
  // const { dynamicPageModel, buttonAction, cmsInfo, history } = props;
  const { dynamicPageModel, buttonAction, history } = props;
  const [isLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const [offset, setOffset] = useState(0);

  const cardColor1 = '#01528a';
  const cardColor2 = '#0183bf';
  const cardColor3 = '#ffb30f';
  const cardColor4 = '#f3c156';

  // const BGDecoration = {
  //   backgroundImage: `url(${BackgroundDecorationDots})`,
  //   backgroundColor: `${color}`,
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  //   backgroundRepeat: 'no-repeat',
  //   // backgroundBlendMode: `${bgBlend}`,
  //   // borderColor: `${bgColor}`,
  // };

  const featuredLinks = [
    {
      icon: DevelopmentIcon,
      image: '',
      header: 'Economic & Workforce Development',
      description: 'Unlock West Baton Rouge’s economic and workforce potential with us.',
      link: 'http://example.com/contact',
      color: cardColor1,
      class: 'development',
    },
    {
      icon: LeadershipIcon,
      image: '',
      header: 'Leadership Development',
      description: 'Learn the values that fuel our unique leadership programs.',
      link: 'http://example.com/about',
      color: cardColor2,
      class: 'leadership',
    },
    {
      icon: MembershipIcon,
      image: '',
      header: 'Membership Engagment',
      description: 'For exclusive events and research, become a member today.',
      link: 'http://example.com/home',
      color: cardColor3,
      class: 'membership',
    },
    {
      icon: RelationsIcon,
      image: '',
      header: 'Advocacy & Government Relations',
      description: 'Navigate policy complexities with our high-quality resources.',
      link: 'http://example.com/products',
      color: cardColor4,
      class: 'relations',
    },
  ];

  const featuredNewsData = [
    {
      date: 'Aug 24th',
      header: '1 Economic Growth',
      title: 'New Businesses in Downtown Area Boost Economy',
      description: 'With the recent opening of several new businesses in the downtown area, local economic indicators suggest an uptick in commerce and job opportunities.',
      link: '/news/new-businesses-downtown'
    },
    {
      date: 'Aug 23rd',
      header: '2 Workshop Series',
      title: 'Sustainability in Business: A New Workshop Series',
      description: 'Join us for a 5-part workshop series on integrating sustainable practices into your business operations. Learn from industry experts and gain a competitive edge.',
      link: '/news/sustainability-workshop'
    },
    {
      date: 'Aug 22nd',
      header: '3 Community Spotlight',
      title: 'Local Entrepreneur Wins National Award',
      description: 'Jane Doe, founder of Doe Industries, has been recognized nationally for her innovative solutions in tech and her contributions to the community.',
      link: '/news/jane-doe-award'
    },
    {
      date: 'Aug 21st',
      header: '∞ Infrastructure Development',
      title: 'Upcoming Roadwork: What You Need to Know',
      description: 'As part of our ongoing commitment to improving infrastructure, several major roads will be under construction over the next few months. Find out more about timelines and detours.',
      link: '/news/upcoming-roadwork'
    },
  ];

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  useEffect(() => {
    const rotatingBanners = dynamicPageModel.pageContentSectionBanners.find((x) => x.bannerId > 0);
    if (rotatingBanners) {
      setBanners(rotatingBanners.banner.bannerItems);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => setOffset(window.pageYOffset);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const bannerSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,

    responsive: [
      {
        breakpoint: 480,
        settings: { dots: false }
      }
    ]
  };

  // const featuredNewsSlider = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   arrows: true,
  //   autoplay: true,
  //   autoplaySpeed: 5000,

  //   responsive: [
  //     {
  //       breakpoint: 1800,
  //       settings: { slidesToShow: 3 }
  //     },
  //     {
  //       breakpoint: 1600,
  //       settings: { slidesToShow: 3 }
  //     },
  //     {
  //       breakpoint: 1350,
  //       settings: { slidesToShow: 2.5 }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: { slidesToShow: 1 }
  //     },
  //   ]
  // };

  // const navigateToLink = (link) => {
  //   window.open(link, '_blank');
  // };

  return (
    <>
      {(isLoading) && <Overlay hasLoader />}
      <CustomBlock>
        {/* BANNER SECTION */}
        <CustomBlock className="banner-container">
          <Slider {...bannerSliderSettings}>
            {
              banners.map((banner) => (
                <Banner
                  // isDynamicBanner
                  isTitleUppercase
                  isReversed={banner.alignment === constants.CONTENT_SECTION.RightAlignment}
                  isCentered={banner.alignment === constants.CONTENT_SECTION.CenterAlignment}
                  // title={banner.title}
                  title={(
                    <p className="custom-banner-title light--clr size-xxl" style={{ lineHeight: '55px !important' }}>
                      <span className="primary-lighter--clr fw-500 size-xl">WHERE</span> COMMERCE
                      <br style={{ width: '50px' }} />
                      <span className="primary-lighter--clr ml-50 fw-500 size-xl">MEETS</span> COMMUNITY
                    </p>
                  )}
                  // imageSource={`${process.env.REACT_APP_API_URL}Attachments${banner.imagePath}`}
                  imageSource={BannerImage}
                  // description={banner.description}
                  description={<p className="custom-banner-description">Empowering business growth, driving academic excellence, and connecting the vibrant communities of West Baton Rouge.</p>}
                  primaryActionText={banner.primaryButtonText}
                  primaryActionOnClick={() => {
                    buttonAction(banner.primaryButtonActionType, banner.primaryButtonLink);
                  }}
                  secondaryActionText={banner.secondaryButtonText}
                  secondaryActionOnClick={() => {
                    buttonAction(banner.secondaryButtonActionType, banner.secondaryButtonLink);
                  }}
                  offset={offset}
                />
              ))
            }
          </Slider>
        </CustomBlock>

        {/* FEATURED LISTING */}
        <CustomBlock className="featured-listing">
          {featuredLinks.map((item) => (
            <button
              onClick={() => {
                history.push('/about-the-chamber');
              }}
              className={`item ${item.class}`} style={{ backgroundImage: `url(${BackgroundPatternCircles})`, backgroundColor: `${item.color}`, color: `${item.color}` }}
            >
              <Image containerClassName="item__icon" source={item.icon} />
              <h2 className="details__header mb-15 fw-700 white--clr">{item.header}</h2>
              <p className="details__description fw-300 white-clr">{item.description}</p>
            </button>
          ))}

        </CustomBlock>

        {/* WEBSITE INTRO */}
        <CustomBlock className="responsive-content-section">
          <CustomBlock className="website-intro pb-60">
            <CustomBlock className="svg-container mb-30">
              <img src={WelcomeToWBRCC} alt="Welcome to West Baton Rouge Chamber of Commerce" />
            </CustomBlock>

            <p className="responsive-content-section__description description mid-grey--clr size-mdlg pb-30">Your hub for business and community, built on four pillars: Economic Growth, Government, Membership, and Leadership. Let&rsquo;s thrive together.</p>
          </CustomBlock>
        </CustomBlock>

        {/* ABOUT, EVENTS, CONTACT INTROS */}
        <CustomBlock className="responsive-content-section">
          <svg
            width="100%"
            height="600"
            // viewBox="0 0 1901 602"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            className="background-decoration--dark-light-waves"
          >
            <path
              d="M0.0918495 73.5986L0.0918495 397.88C107.439 321.334 469.533 120.143 987.763 199.274C1483.25 274.932 1645.64 264.478 1900.09 205.518L1900.09 172.9C1530.82 216.344 1327.56 180.658 922.596 73.5986C582.799 -25.707 342.303 -22.6017 0.0918495 73.5986Z"
              fill="#94DDFF"
              fillOpacity="0.835294"
            />
            <path
              d="M0.0918495 397.88L0.0918495 601.845C116.748 476.447 478.554 214.386 987.763 252.028C1489.86 289.144 1583.53 303.825 1900.09 221.196L1900.09 205.518C1645.64 264.478 1483.25 274.932 987.763 199.274C469.533 120.143 107.439 321.334 0.0918495 397.88Z"
              fill="#C3E9FB"
              fillOpacity="0.835294"
            />
          </svg>
          <CustomBlock className="mult-intro pb-60">
            <p className="responsive-content-section__header">Elevating Business & Community</p>
            <p className="responsive-content-section__description pb-30">Your hub for growth in West Baton Rouge.</p>
            <CustomBlock className="listing-block">
              <CustomBlock className="item" onClick={() => history.push('/about-the-chamber')}>
                <p className="header size-lg fw-500">About</p>
                <hr />
                <p className="description mid-grey--clr size-sm">Learn who we are, our mission, vision, and how we contribute to the community.</p>
              </CustomBlock>
              <CustomBlock className="item" onClick={() => history.push('/events')}>
                <p className="header size-lg fw-500">Events</p>
                <hr />
                <p className="description mid-grey--clr size-sm">Check out upcoming and annual events designed to connect businesses and foster growth.</p>
              </CustomBlock>
              <CustomBlock className="item" onClick={() => history.push('/resources')}>
                <p className="header size-lg fw-500">Resources</p>
                <hr />
                <p className="description mid-grey--clr size-sm">Find useful tools and information to help your business succeed.</p>
              </CustomBlock>
            </CustomBlock>
          </CustomBlock>
        </CustomBlock>

        {/* HOMEPAGE CONTENT SECTION - MEET TEAM & BOARD */}
        {/* <CustomBlock className="responsive-content-section">
          <CustomBlock className="intro-to-team pt-60 pb-60">
            <img
              src={`${BackgroundDecorationDots}`}
              className="background-decoration_dots"
              alt="Background Decoration"
            />
            <p className="responsive-content-section__header">Business Catalysts</p>
            <p className="responsive-content-section__description pb-30">
              Get to know the dedicated professionals who are shaping the business landscape in West Baton Rouge.
            </p>
            <CustomBlock className="actions">
              <button
                type="button"
                className="button"
                onClick={() => history.push('/team-members')}
              >
                Meet the Team
              </button>
              <button
                type="button"
                className="button"
                onClick={() => history.push('/board-of-directors')}
              >
                Discover our Board
              </button>
            </CustomBlock>
          </CustomBlock>
        </CustomBlock> */}

      </CustomBlock>
    </>
  );
};

export default HomePage;