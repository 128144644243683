// DEPENDENCIES
import React, { useState } from 'react';
// ICONS
import { GoGoal, GoLightBulb } from 'react-icons/go';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import Accordion from '../../../components/Accordion';
// ASSETS - ABOUT PAGE
import BannerImage from '../../../assets/img/banner-image_default.png';
import BackgroundTexture from '../../../assets/img/background-texture_circles.png';

const StatementBlock = ({
  flex,
  icon,
  header,
  description,
  className
}) => (
  <CustomBlock className="statement" style={{ flex: `${flex}` }}>
    <CustomBlock className={`icon-wrapper ${className} mb-20`}>
      {icon}
    </CustomBlock>
    <p className="header primary--clr fw-700 mb-10">{header}</p>
    <p className="description dark--clr">
      {description}
    </p>
  </CustomBlock>
);

const AboutPage = () => {
  const [isLoading] = useState(false);

  const accordionData = [
    {
      id: '0',
      header: 'Economic and Workforce Development',
      color: '#01528A',
      blend: 'soft-light',
      contentList: [
        'Refine and organize additional industry-specific round tables and events to provide resources that are specific to that constituency\'s needs.',
        'Expand how we educate & inform businesses on the opportunities & challenges regarding economic growth & re-development.',
        'Dedicate more resources to focus on identifying, coordinating, and promoting small business initiatives.'
      ]
    },
    {
      id: '1',
      header: 'Advocacy & Governmental Relations',
      color: '#0183BF',
      blend: 'soft-light',
      contentList: [
        'Develop a plan to strategically engage and inform Local and State elected officials on WBR initiatives to position the WBR as an essential community resource.',
        'Develop a plan to strategically engage & inform State elected officials on WBR initiatives to position the WBR as an essential community resource.',
        'Refine WBR\'s annual Legislative Priorities by gathering input from West Baton Rouge’s business constituents.',
        'Identify & educate businesses on policies (local, state, & federal) & regulations that create barriers to business success.',
        'Communicate policy and legislative agenda - marketing & communications strategy on who & how we share details.'
      ]
    },
    {
      id: '2',
      header: 'Membership Engagement',
      color: '#FFB30F',
      blend: 'color-burn',
      contentList: [
        'Build Diversity, Equity and Inclusion into each area of the Chamber membership experience.',
        'Update WBR Chamber\'s business/business leader recognition/awards program(s) to highlight successful minority business owners.',
        'Review, refresh and simplify existing benefits for joining and getting exposure from WBR Chamber.',
        'Develop additional membership opportunities; providing access to Chamber resources to all businesses in West Baton Rouge.'
      ]
    },
    {
      id: '4',
      header: 'Leadership Development',
      color: '#F3C156',
      blend: 'color-burn',
      contentList: [
        'Identify and develop a talent professional development plan for WBR to ensure leadership growth & continuity.',
        'Create a structure that oversees the approach and implementation of the strategic plan objectives.',
        'Enhance the existing Young Professionals Program to attract and engage more \'Millennials\' and \'Generation Z\' throughout the Parish.',
        'Expand & enhance partnerships outside West Baton Rouge to encourage regional leadership development.'
      ]
    }
  ];

  return (
    <CustomBlock className="content-page_about-the-chamber">
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="About the Chamber"
        imageSource={BannerImage}
      />

      <CustomBlock className="responsive-content-section">
        <CustomBlock className="statements-block pb-60">
          <StatementBlock
            flex="1.3"
            icon={<GoGoal className="icon white--clr" />}
            header="Mission"
            description="To be the voice of business while promoting growth, prosperity and quality of life in West Baton Rouge Parish."
            className="mission"
          />
          <StatementBlock
            flex="2"
            icon={<GoLightBulb className="icon white--clr" />}
            header="Vision"
            description="The West Baton Rouge Chamber is a proactive and catalytic organization dedicated to engaging the business community and other stakeholders to foster a premier, diverse and growing Parish."
            className="vision"
          />
        </CustomBlock>
      </CustomBlock>

      <CustomBlock className="responsive-content-section pb-100">
        <p className="responsive-content-section__header">Four Pillars for West Baton Rouge&apos;s Growth</p>
        <p className="responsive-content-section__description pb-30">The West Baton Rouge Chamber outlines four key pillars: Economic Development, Government Relations, Membership Engagement, and Leadership. Each aims to drive community and economic growth.</p>
        <Accordion
          data={accordionData}
          backgroundTexture={BackgroundTexture}
        // isInitiallyExpanded
        />
      </CustomBlock>
    </CustomBlock>
  );
};

export default AboutPage;