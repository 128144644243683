// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';

const MemberListing = (props) => {
  const {
    members,
    defaultImage,
    className,
    containerClassName,
    renderCustomContent = null
  } = props;

  return (
    <CustomBlock hasNoContainer className={`listing-content ${containerClassName}`}>
      {members.map((member) => (
        <CustomBlock className={`listing-item ${className}`}>
          <img
            src={member.image}
            title={member.name}
            alt={member.name}
            className="listing-item__image"
            onError={(e) => {
              if (e.target.src !== defaultImage) {
                e.target.src = defaultImage;
              }
            }}
          />

          <h2 className="listing-item__title">{member.name}</h2>

          {member.position && <em className="listing-item__position">{member.position}</em>}

          {member.email && (
            <a
              href={`mailto:${member.email}`}
              rel="noopener noreferrer"
              target="_blank"
              className="listing-item__email"
            >
              {member.email}
            </a>
          )}

          {member.company && (
            member.companyLink ? (
              <a
                href={member.companyLink}
                className="listing-item__link"
                rel="noopener noreferrer"
                target="_blank"
              >
                {member.company}
              </a>
            ) : (
              <span className="listing-item__link">{member.company}</span>
            )
          )}

          {member.memberType === 'Team Member' && member.bioLink && (
            <CustomBlock className="text-block-action-container">
              <Button
                text="Read More"
                size="sm"
                type="button"
                className="primary--bg mt-20"
                to={member.bioLink}
              />
            </CustomBlock>
          )}

          {renderCustomContent && renderCustomContent(member)}
        </CustomBlock>
      ))}
    </CustomBlock>
  );
};

MemberListing.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    position: PropTypes.string,
    company: PropTypes.string,
    companyLink: PropTypes.string,
    imagePath: PropTypes.string
  })).isRequired,
  defaultImage: PropTypes.string,
  className: PropTypes.string,
  renderCustomContent: PropTypes.func
};

MemberListing.defaultProps = {
  defaultImage: '',
  className: '',
  renderCustomContent: null
};

export default MemberListing;